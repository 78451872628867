import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import SocialMediaContentService from "../services/socialmediacontent.service";
// import socialMediaContentService from "src/services/socialmediacontent.service";

// Create (Add) a new Social Media content
export const addSocialMediaContent = createAsyncThunk(
  "socialMediaContent/add",
  async ({ url, thumbnail, isActive, sort_order, type }, thunkAPI) => {
    try {
      const data = await SocialMediaContentService.addSocialMediaContent(url, thumbnail,isActive, sort_order, type);
      thunkAPI.dispatch(setMessage(data.message));
      return data.content;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getSocialMediaContent = createAsyncThunk(
  "socialMediaContent/get",
  async (type, thunkAPI) => {
    try {
      const data = await SocialMediaContentService.getSocialMediaContent(type);
      console.log('data',data.docs);
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getActiveSocialMediaContent = createAsyncThunk(
  "socialMediaContent/get/active",
  async (type, thunkAPI) => {
    try {
      const data = await SocialMediaContentService.getActiveSocialMediaContent(type);
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSocialMediaContentById = createAsyncThunk(
  "socialMediaContent/getById",
  async (id) => {
    try {
      const data = await SocialMediaContentService.getSocialMediaContentById(id)
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateSocialMediaContent = createAsyncThunk(
  "socialMediaContent/update",
  async ({ id, updateData }, thunkAPI) => {
    try {
      const data = await SocialMediaContentService.updateSocialMediaContent(id, updateData.thumbnail, updateData.url,  updateData.sort_order, updateData.isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteSocialMediaContent = createAsyncThunk(
  "socialMediaContent/delete",
  async (id, thunkAPI) => {
    try {
      const data = await SocialMediaContentService.deleteSocialMediaContent(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSocialMediaContentOrder = createAsyncThunk(
  "socialMediaContent/updateOrder",
  async (platforms, thunkAPI) => {
    try {
      const response = await SocialMediaContentService.updateSocialMediaContentOrder(platforms);
      thunkAPI.dispatch(setMessage(response.message));
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


 
const initialState = { isLoading: false, content: [], singleContent: null };

const socialMediaContentSlice = createSlice({
  name: "socialMediaContent",
  initialState,
  extraReducers: {
    [addSocialMediaContent.pending]: (state) => {
      state.isLoading = true;
    },
    [addSocialMediaContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [addSocialMediaContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [getSocialMediaContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [getSocialMediaContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [updateSocialMediaContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [updateSocialMediaContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [deleteSocialMediaContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [deleteSocialMediaContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [getSocialMediaContentById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleContent = action.payload;
    },
    [getSocialMediaContentById.rejected]: (state) => {
      state.isLoading = false;
      state.singleContent = null;
    },
  },
});

const { reducer } = socialMediaContentSlice;
export default reducer;
