import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;



const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addSocialMediaContent = async ( url, thumbnail, isActive, sort_order, type ) => {
  return await axios.post(
    API_URL + "admin/addSocialMediaContent",
    {
      url,
      thumbnail,
      isActive,
      sort_order,
      type
    },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};

const getSocialMediaContent = async (type) => {
  return await instance.get(API_URL + `admin/socialMediaContent/${type}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getActiveSocialMediaContent = async (type) => {
  return await axios.get(API_URL + "users/activesocialmediacontent/" + type, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};


const updateSocialMediaContent = async (id, thumbnail, url, sort_order, isActive) => {
  return await axios.patch(
    API_URL + `admin/updateSocialMediaContent/${id}`,
    { thumbnail, url, sort_order, isActive },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};


const deleteSocialMediaContent = async (id) => {
  return await axios.delete(API_URL + `admin/deleteSocialMediaContent/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getSocialMediaContentById = async (id) => {
  return await axios.get(API_URL + `admin/socialmediacontentbyid/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
}

const updateSocialMediaContentOrder = async (platforms) => {
  return await axios.post(API_URL + "admin/updateSocialMediaContentOrder", { platforms }, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const socialMediaContentService = {
  addSocialMediaContent,
  getSocialMediaContent,
  updateSocialMediaContent,
  deleteSocialMediaContent,
  getSocialMediaContentById,
  updateSocialMediaContentOrder,
  getActiveSocialMediaContent
};
export default socialMediaContentService;
