import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;



const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addSocialMediaPlatform = async (name, url, followerCount, isActive) => {
  return await axios.post(
    API_URL + "admin/addSocialMediaPlatform",
    {
      name,
      url,
      followerCount,
      isActive,
    },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};

const getSocialMediaPlatform = async () => {
  return await instance.get(API_URL + "admin/socialMediaPlatform", {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getActiveSocialMediaPlatform = async () => {
  return await axios.get(API_URL + "userauth/socialMediaPlatform/active", {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};


const updateSocialMediaPlatform = async (id, updateData) => {
  return await axios.patch(
    API_URL + `admin/socialMediaPlatform/${id}`,
    { updateData },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};

const deleteSocialMediaPlatform = async (id) => {
  return await axios.delete(API_URL + `admin/deleteSocialMediaPlatform/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getSocialMediaPlatformById = async (id) => {
  return await axios.get(API_URL + `admin/getSocialMediaPlatform/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
}

const updateSocialMediaPlatformOrder = async (platforms) => {
  return await axios.post(API_URL + "admin/updateSocialMediaPlatformOrder", { platforms }, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const socialMediaPlatformService = {
  addSocialMediaPlatform,
  getSocialMediaPlatform,
  updateSocialMediaPlatform,
  deleteSocialMediaPlatform,
  getSocialMediaPlatformById,
  getActiveSocialMediaPlatform,
  updateSocialMediaPlatformOrder
};
export default socialMediaPlatformService;
