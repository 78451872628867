import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./scss/style.scss";
import "./style.css";
import Login from "./views/admin/pages/login/Login";
import { socialMediaContentTypes } from "./helper";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Routes
const AdminRoutes = React.lazy(() => import("./AdminRoutes"));
const UserRoutes = React.lazy(() => import("./UserRoutes"));
const InstagramContentRoutes = React.lazy(() => import("./InstagramContentRoutes"));
const SocialMediaContentRoutes = React.lazy(() => import("./SocialMediaContentRoutes"));

class App extends Component {
  render() {
    const socialMediaTypes = Object.keys(socialMediaContentTypes);
    console.log(socialMediaTypes);
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Diverseland - Your Source for Culture & Entertainment</title>
          <meta name="description" content="Diverse Land - Your premier destination for culture, entertainment, fashion, and lifestyle content." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://diverseland.com/" />
          <meta property="og:title" content="Diverse Land" />
          <meta property="og:description" content="Your premier destination for culture, entertainment, fashion, and lifestyle content." />
          <meta property="og:image" content="/path-to-your-logo.png" />

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content="https://diverseland.com/" />
          <meta name="twitter:title" content="Diverse Land" />
          <meta name="twitter:description" content="Your premier destination for culture, entertainment, fashion, and lifestyle content." />
          <meta name="twitter:image" content="/path-to-your-logo.png" />

          {/* Keywords */}
          <meta name="keywords" content="culture, entertainment, fashion, lifestyle, diverse land, news, trends" />

          {/* Favicon */}
          <link rel="icon" type="image/png" href="/favicon.ico" />
        </Helmet>

        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/dl/lognow/adm" name="Login Page" element={<Login />} />
              <Route exect path="/admin/*" element={<AdminRoutes />} />
              <Route exect path="/instagram-content" element={<InstagramContentRoutes />} />
              {socialMediaTypes.map(type => (
                <Route key={type} exect path={`/${type}`} element={<SocialMediaContentRoutes />} />
              ))}
              <Route exect path="/*" element={<UserRoutes />} />
            </Routes>
          </Suspense>
        </Router>
      </>
    );
  }
}

export default App;
