import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addInnerPages = async (
  item,
  itemId,
  category,
  image,
  title,
  description,
  isActive,
  type,
  slug
) => {
  return await axios
    .post(
      API_URL + "admin/addinnerpages",
      {
        item,
        itemId,
        category,
        image,
        title,
        description,
        isActive,
        type,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addCategoryInnerPages = async (
  image,
  category,
  title,
  sequence,
  description,
  isActive,
  id,
  subtitle,
  slug
) => {
  return await axios
    .post(
      API_URL + "admin/addcategoryinnerpages",
      {
        image,
        category,
        title,
        sequence,
        description,
        isActive,
        id,
        subtitle,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getInnerPage = async () => {
  return await instance
    .get(API_URL + "admin/innerpages", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateInnerPages = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updateinnerpages/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateCategoryInnerPages = async (itmId, id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatecategoryinnerpages/${id}`,
      { itmId, isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateInnerPagesData = async (
  id,
  item,
  itemId,
  category,
  image,
  oldImage,
  title,
  description,
  isActive,
  type,
  slug
) => {
  return await axios
    .put(
      API_URL + `admin/updateinnerpages/${id}`,
      {
        item,
        itemId,
        category,
        image,
        oldImage,
        title,
        description,
        isActive,
        type,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateCategoryInnerPageData = async (
  id,
  innerid,
  category,
  image,
  oldImage,
  title,
  description,
  sequence,
  subtitle,
  slug
) => {
  return await axios
    .put(
      API_URL + `admin/updateinnercategorypages/${id}`,
      {
        innerid,
        category,
        image,
        oldImage,
        title,
        description,
        sequence,
        subtitle,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteInnerPages = async (id) => {
  return await axios
    .delete(API_URL + `admin/deleteinnerpages/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const deleteCategoryInnerPages = async (id, itmId) => {
  return await axios
    .patch(
      API_URL + `admin/deletecategoryinnerpages/${id}`,
      {
        itemId: itmId,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateHomeFrontPages = async (request) => {
  const { itmId, id, ...rest } = request;
  return await axios
    .patch(
      API_URL + `admin/updatehomepageitems/${id}`,
      { itmId, ...rest },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addNavInnerSubItems = async (
  title,
  category,
  content,
  innerImage,
  price,
  url,
  type,
  description,
  id,
  innerId
) => {
  return await axios
    .patch(
      API_URL + `admin/addinnersubarticles/${id}`,
      {
        innerId,
        title,
        category,
        content,
        innerImage,
        price,
        url,
        type,
        description,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContent = async (id, content, innerId) => {
  return await axios
    .patch(
      API_URL + `admin/updateinnercontent/${id}`,
      { content, innerId },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteInnerSubItem = async (itemId, id, innerId, oldImage) => {
  return await axios
    .patch(
      API_URL + `admin/deleteinnersubitem/${id}`,
      {
        itemId,
        innerId,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const silentUpdateContent = async (id, content, innerId) => {
  return await axios
    .patch(
      API_URL + `admin/silentupdateinnercontent/${id}`,
      { content, innerId },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteInnerPageThumbnail = async (id) => {
  try {
    const response = await instance.delete(
      API_URL + `admin/deleteinnerpagesthumbnail/${id}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const adsService = {
  addInnerPages,
  addCategoryInnerPages,
  addNavInnerSubItems,
  getInnerPage,
  updateContent,
  updateInnerPages,
  updateCategoryInnerPages,
  updateCategoryInnerPageData,
  updateInnerPagesData,
  deleteInnerPages,
  deleteCategoryInnerPages,
  deleteInnerSubItem,
  updateHomeFrontPages,
  silentUpdateContent,
  deleteInnerPageThumbnail
};

export default adsService;
